import Vue from "vue";
import VueRouter from "vue-router";
// 解决导航栏或者底部导航tabBar中的vue-router在3.0版本以上频繁点击菜单报错的问题。
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/Login.vue"),
    meta: { name: "登录" },
  },
  {
    path: "/register",
    name: "Register",
    component: () => import("../views/Register.vue"),
    meta: { name: "注册" },
  },
  {
    path: "/totpVerify",
    name: "TotpVerify",
    component: () => import("../views/TotpVerify.vue"),
    meta: { name: "TOTP动态码校验" },
  },
  {
    path: "/403",
    name: "403",
    component: () => import("../views/error/403.vue"),
    meta: { name: "无权限访问" },
  },
  {
    path: "/404",
    name: "404",
    component: () => import("../views/error/404.vue"),
    meta: { name: "找不到访问资源" },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

//设置路由
export const setRoutes = () => {
  const menuList = localStorage.getItem("menus");
  if (menuList) {
    //获取当前路由对象的名称数组
    const currentRouteNames = router.getRoutes().map((v) => v.name);
    if (!currentRouteNames.includes("Manager")) {
      //拼装动态路由
      const manageRoute = {
        path: "/",
        name: "Manager",
        component: () => import("../views/Manager.vue"),
        redirect: "/home",
        children: [
          {
            path: "personInfo",
            name: "个人信息",
            component: () => import("../views/manager/PersonInfo.vue"),
            meta: { name: "个人信息" },
          },
          {
            path: "editPassword",
            name: "修改密码",
            component: () => import("../views/manager/EditPassword.vue"),
            meta: { name: "修改密码" },
          },
        ],
      };
      //解析菜单集合
      const menus = JSON.parse(menuList);
      menus.forEach((item) => {
        if (item.path) {
          let itemMenu = {
            path: item.path.replace("/", ""),
            name: item.name,
            component: () =>
              import("../views/manager/" + item.pagePath + ".vue"),
            meta: {
              order: item.sort, // 设置菜单的排序值
            },
          };
          manageRoute.children.push(itemMenu);
        } else if (item.children.length) {
          item.children.forEach((item) => {
            if (item.path) {
              let itemMenu = {
                path: item.path.replace("/", ""),
                name: item.name,
                component: () =>
                  import("../views/manager/" + item.pagePath + ".vue"),
                meta: {
                  order: item.sort, // 设置菜单的排序值
                },
              };
              manageRoute.children.push(itemMenu);
            }
          });
        }
      });
      //将动态路由添加到现在的路由对象中
      router.addRoute(manageRoute);
    }
  }
};
//重置路由
setRoutes();

//退出时重置路由方法
export const resetRouter = () => {
  router.matcher = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
  });
};

/**
 * to:到达的路由
 * from：开始的路由
 * next:帮助路由跳转的函数
 */
router.beforeEach((to, from, next) => {
  //未找到路由
  if (!to.matched.length) {
    const storeMenus = localStorage.getItem("menus");
    if (storeMenus) {
      next("/404");
    } else {
      next("/login");
    }
  }
  next();
});
export default router;
