import router from "@/router";
import axios from "axios";

//创建axios对象
// const request = axios.create({
//   // 后端的接口地址  ip:port
//   baseURL: "http://59.110.226.241:80/api",
//   timeout: 30000,
// });
const request = axios.create({
  // 后端的接口地址  ip:port
  baseURL: "https://www.ice-berg.cloud/api",
  timeout: 30000,
});

// const request = axios.create({
//   // 后端的接口地址  ip:port
//   baseURL: "http://localhost:8078",
//   timeout: 30000,
// });

// request 拦截器
// 可以自请求发送前对请求做一些处理
// 比如统一加token，对请求参数统一加密
request.interceptors.request.use(
  (config) => {
    config.headers["Content-Type"] = "application/json;charset=utf-8";
    let user = localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("user"))
      : null;
    // 设置请求头
    if (user != null) {
      config.headers["Authorization"] = user.token;
    }
    return config;
  },
  (error) => {
    console.error("request error: " + error);
    if (res.code === "400") {
      return new error("token已过期,请重新登录");
      router.push("/login");
    }
    return Promise.reject(error);
  }
);

// response 拦截器
// 可以在接口响应后统一处理结果
request.interceptors.response.use(
  (response) => {
    let res = response.data;
    // 兼容服务端返回的字符串数据
    if (typeof res === "string") {
      res = res ? JSON.parse(res) : res;
    }
    if (res.code === "401" || res.code === 405) {
      router.push("/login");
    }

    return res;
  },
  (error) => {
    if (
      (error.response && error.response.status === 405) ||
      error.response.status === 400
    ) {
      router.push("/login");
    }
    console.error("response error: " + error);
    return Promise.reject(error);
  }
);

export default request;
