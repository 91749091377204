import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "@/assets/css/global.css";
import "@/assets/css/theme/index.css";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import request from "@/util/request";

Vue.config.productionTip = false;
Vue.use(ElementUI, { size: "small" });
Vue.prototype.$request = request;
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
